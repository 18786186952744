export default {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    position: "absolute",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    marginRight: "-50%",
    border: "0",
    background: "none",
    overflow: "none",
    transform: "translate(-50%, -50%)",
  },
}
