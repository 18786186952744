/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import Modal from "react-modal"
import PageWrapper from "../../layouts/PageWrapper"
import HomeLogo from "../../components/HomeLogo"
import ModalStyle from "../../components/ModalStyle"

function getParent() {
  return document.querySelector("#___gatsby")
}

const VideoModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={ModalStyle}
    >
      <a
        href="#"
        className="close delete is-large"
        onClick={props.closeModal}
      />
      <video width={props.width} height={props.height} controls>
        <source src={props.source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  )
}

// TODO: Change here
const videos = [
   {
    title: "Nautica Pontian 2",
    source: "/video/N_Pontian2.mp4",
    thumbnail: "/video/thumb_np2.jpeg",
  },
   {
    title: "Sea Trial of MT Nautica Kluang 2",
    source: "/video/NKL2.mp4",
    thumbnail: "/video/nkl2.JPG",
  },
   {
    title: "27th Annual General Meeting",
    source: "/video/AGMVM27th.mp4",
    thumbnail: "/video/agmvc.JPG",
  },
  {
    title: "Delivery Ceremony of M.T. Nautica Kluang2",
    source: "/video/Kluang2.mp4",
    thumbnail: "/video/kl2.jpg",
  },
  {
    title: "Annual General Meeting",
    source: "/video/agm24mb.mp4",
    thumbnail: "/video/AGMVM.jpg",
  },
  {
    title: "Mekar Bergading Sails Away",
    source: "/video/bergading.mp4",
    thumbnail: "/video/first.jpg",
  },
  {
    title: "Nautica Tanjung Puteri XXX",
    source: "/video/puteri.mp4",
    thumbnail: "/video/second.jpg",
  },
  {
    title: "Fois Nautica Tembikai",
    source: "/video/tembikai.mp4",
    thumbnail: "/video/third.jpg",
  },
  {
    title: "Nautica Gambir and Nautica Langsat Launch Ceremony",
    source: "/video/langsat.mp4",
    thumbnail: "/video/fourth.jpg",
  },
  {
    title: "EAT Corporate Video",
    source: "/video/eat_corporate_video.mp4",
    thumbnail: "/video/eat_corporate_video_thumbnail.png",
  },
]

export default class VideosPage extends Component {
  constructor() {
    super()

    this.state = {
      // Change Here for new video
      isOpen: [false, false, false, false],
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(index) {
    this.setState((state, props) => {
      return {
        isOpen: [
          ...state.isOpen.slice(0, index),
          true,
          ...state.isOpen.slice(index + 1),
        ],
      }
    })
  }

  closeModal(index) {
    this.setState((state, props) => {
      return {
        isOpen: [
          ...state.isOpen.slice(0, index),
          false,
          ...state.isOpen.slice(index + 1),
        ],
      }
    })
  }

  render() {
    return (
      <PageWrapper>
        <Helmet>
          <title>Videos - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <HomeLogo />

                  <h1 className="title" id="title-line">
                    Videos
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" style={{ paddingBottom: `3rem` }}>
          <div className="container">
            <div className="columns is-multiline">
              {videos.map((video, index) => (
                <div className="column is-4" id="video-title">
                  <div className="card video-card">
                    <h1 className="title is-4">{video.title}</h1>
                    <VideoModal
                      source={video.source}
                      width="720"
                      height="540"
                      isOpen={this.state.isOpen[index]}
                      closeModal={() => this.closeModal(index)}
                    />
                    <img
                      src={video.thumbnail}
                      onClick={() => this.openModal(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </PageWrapper>
    )
  }
}
